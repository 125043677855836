.main-container{
    background-color: #FCFCFC;
    height: 100vh;
    overflow: hidden;
    position: relative;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.login-background{
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.login-container{
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
   
}
.inner-content{
    width: 100%;
}
.flabs-logo{
    margin-top: 25px;
    margin-left: 50px;
}
.flabs-logo-size{
    width: 200px;
    height: 35px;
}
.switch-side{
    margin-bottom: 20px;
}
.user-link{
    font-size: 2rem;
    text-decoration-line: underline;
}
.admin-link{
    font-size: 2rem;
    margin-left: 8%;
    color: black;
    
}
.input-bar-div{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 22px;
    margin-bottom: 22px;
}
.input-bar{
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 2px solid #DCDCDC;
}
.error-text{
    color: red;
    margin-top: -18px;
}
.otp-button{
    padding: 6.4px 15px;
    border: none;
    background-color: blue;
    color: white;
}
.otp-button:hover{
    cursor: pointer;
}
.account-login{
    margin-top: 22px;
}
.resend-otp{
    display: flex;
    justify-content: space-between;
}


@media screen and (max-width: 1024px) {
    .login-container{
        width: 40%;
    }
}
@media screen and (max-width: 768px) {
    .login-container{
        width: 60%;
    }
}

@media screen and (max-width: 480px) {
    .login-background{
        height: 90vh;
    }
    .login-container{
        width: 85%;
    }
    .flabs-logo{
        margin-top: 25px !important;
        margin-left: -5px !important;
        
    }
}