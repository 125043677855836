@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

.report-status-container {
    max-width: 500px;
    width: 100%;
    margin: auto;
    font-family: 'Inter';
    overflow-x: hidden;
}

.rs-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 24px;
}

.rs-header p {
    max-width: 260px;
    width: 100%;
    font-size: 12px;
    margin: 0;
    color: rgba(0, 0, 0, 0.45);
}

.rs-header h1 {
    font-size: 20px;
    font-weight: 500;
    margin: 8px 0;
}

.patient-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 0;
    gap: 4px;
    padding-left: 18px;
    margin: 24px 0;
    background-color: rgba(0, 0, 0, 0.04);
}

.patient-info h2 {
    font-size: 14px;
    margin: 0;
    font-weight: 500;
    line-height: 20px;
}

.patient-info p {
    font-size: 12px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.45);
    margin: 0;
}

.rs-title {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    margin-left: 18px;
}

.report-status-steps {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 36px;
    margin-top: 30px;
}

.report-status-steps h3 {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
}

.report-status-steps p {
    margin: 0;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.45);
}

.step-item {
    padding-left: 16px;
}

.call-btn {
    display: flex;
    cursor: pointer;
    background-color: #4678EE;
    position: fixed;
    bottom: 18px;
    right: 18px;
    align-items: center;
    border: none;
    border-radius: 32px;
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
    padding: 12px 20px;
    gap: 8px;
    margin: 0 auto;
    margin-top: 40px;
    user-select: none;
    text-decoration: none;
}

.rs-footer {
    margin-top: 44px;
    margin-bottom: 24px;
    margin-left: 23px;
}