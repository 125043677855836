@import (css)
  url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

.ant-upload-list-text-container,
.ant-upload-list-picture-container {
  width: 32rem !important;
}

.country-input .ant-select-selection-item {
  color: black;
}

@media screen and (max-width: 480px) {
  .ant-picker-panels {
    flex-direction: column;
  }
  .ant-calendar-range {
    width: 340px;
  }
  .ant-calendar-range-part {
    width: 100%;
  }
}

@primary-color: #4678EE;