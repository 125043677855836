.medical-report-container{
    /* background-color: #F0F2F5; */
    background-color: #FFFFFF;
    height: 100vh;
}
.navbar-background{
    background-color: white;
    height: 80px;
}
.medical-report-content{
    width: 90%;
    margin: auto;
  
}
.main-contianer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 93%;
}
.flabs-logo{
    margin-top: 15px;
    margin-left: 25px;
}
.flabs-logo-size{
    height: 40px;
}
.header-left-side{
    margin-left: 3%;
}

.header-avatar-div{
    margin-top: 10px;
    padding-top: 0;
}
.header-avatar-select{
    margin-top: 15px;
}
.heading-bar{
    /* width: 89%;
    margin: auto; */
    margin-top: 40px;
}
.heading-bar-container{
    display: flex;
    justify-content: flex-start;
}
.heading-bar-left{
    flex: 4;
    margin-top: -5px;
}
.heading-bar-right{
    flex: 6;
    display: flex;
    justify-content: space-between;
}
.heading-bar-heading{
    color: #103252;
    font-size: 28px;
    font-weight: 500;
}
.heading-bar-search{
    width: 82%;
}
.mobile-view{
    display: none;
}
.dekstop-view{
    display: block;
}

@media screen and (max-width: 480px) {
    .navbar-background{
        background-color: white !important;
    }
    .medical-report-container{
        background-color: white;
        height: auto;
    }
    .main-contianer{
        padding-top: 12px;
    }
    .flabs-logo{
        margin-left: 0px !important;
    }
    .heading-bar{
        /* width: 89%;
        margin: auto; */
        margin-top: 20px;
    }
    .main-contianer{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100% !important;
    }
    .header-right-side{
        margin-right: 20px;
    }
    .heading-bar-container{
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    .heading-bar-heading{
        color: #103252;
        font-size: 18px;
        font-weight: 500;
    }
    .heading-bar-search{
        width: 90%;
    }
    .dekstop-view{
        display: none;;
    }
    .mobile-view{
        display:block;
    }
    .header-avatar-select{
        margin-top: 10px;
        margin-right: 15px;
    }
    .heading-bar-right{
        flex: 6;
        display: flex;
        flex-direction: column-reverse;
        /* justify-content: space-between; */
    }
    
}